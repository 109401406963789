import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const APSGuidingPrinciple = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.aps_guiding_principle}`,
    message: (
      <>
        <FormattedMessage id="message_aps_guiding_principle" values={commonMessageValues}/>
        <div className="ui bulleted list">
          <div className="item">{messages.message_aps_guiding_principle_item1}</div>
          <div className="item">{messages.message_aps_guiding_principle_item2}</div>
          <div className="item">{messages.message_aps_guiding_principle_item3}</div>
          <div className="item">{messages.message_aps_guiding_principle_item4}</div>
          <div className="item">{messages.message_aps_guiding_principle_item5}</div>
          <div className="item">{messages.message_aps_guiding_principle_item6}</div>
        </div>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.aps_guiding_principle}`} textBlocks={textBlocks}/>);
};

export default APSGuidingPrinciple;